import { api, currentCreche } from '../api'

const NotificationRepository = {
    index () {
        return api().$get(`/${currentCreche().uuid}/notifications`)
    },

    dashboardEvents () {
        return api().$get(`/${currentCreche().uuid}/dashboard-events`)
    },

    getForUser (query = {}) {
        return api().$get('/notifications', query)
    },

    markAsRead (notificationId) {
        return api().$post(`/notifications/${notificationId}/read`)
    },

    markAllAsReadForUser () {
        return api().$post('/notifications/read-all')
    }
}

export default NotificationRepository
